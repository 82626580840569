import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import posthog from "posthog-js";
import { Layout, Spin } from "antd";
import Login from "./components/Login";
import { useAuth } from "./hooks/useAuth";
import ThemeProvider from "./styles/ThemeProvider";
import "./libs/posthog";
import Demo from "./components/Demo";
import { useAutoReload } from "./hooks/useAutoReload";

const { Content } = Layout;

function App() {
  const { user, loading } = useAuth();
  useAutoReload();

  // Identify user in PostHog
  useEffect(() => {
    if (user) {
      posthog.identify(user.uid, {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
      });
    }
  }, [user]);

  if (loading) {
    return (
      <ThemeProvider>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider>
      <Router>
        <Layout style={styles.layout}>
          <Content>
            <Routes>
              <Route path="/" element={<Demo />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/editor/*" element={<Home />} /> */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Content>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

// Styles
const styles = {
  layout: {
    height: "100vh",
    overflow: "hidden",
  },
};

export default App;
