import { useEffect, useRef } from "react";

export const useInactivityReset = (
  onInactivity: () => void,
  timeoutMinutes = 0.01
) => {
  const lastVisibleTimestamp = useRef<number>(Date.now());

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab becomes hidden, store the timestamp
        lastVisibleTimestamp.current = Date.now();
      } else {
        // Tab becomes visible again, check how much time has passed
        const timeInactive =
          (Date.now() - lastVisibleTimestamp.current) / 1000 / 60; // in minutes
        if (timeInactive >= timeoutMinutes) {
          onInactivity();
        }
      }
    };

    // Add visibility change event listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [onInactivity, timeoutMinutes]);
};
