import { useCallback, useEffect } from "react";
import { useVersionCheck } from "./useVersionCheck";
import { useInactivityReset } from "./useInactivityReset";

export const useAutoReload = () => {
  const reloadInfo = useVersionCheck();

  const forceReload = useCallback(() => {
    // Clear cache and reload
    window.location.href = window.location.href + "?reload=" + Date.now();
  }, []);

  useEffect(() => {
    if (reloadInfo === "urgent-reload") {
      forceReload();
    }
  }, [reloadInfo, forceReload]);

  const onInactivity = useCallback(() => {
    if (reloadInfo === "reload") {
      forceReload();
    }
  }, [reloadInfo, forceReload]);

  useInactivityReset(onInactivity, 5);
};
