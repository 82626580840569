import React, { useEffect } from "react";
import { Button, Card, Typography, message } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../libs/firebase";
import { useAuth } from "../hooks/useAuth";
import { Navigate } from "react-router-dom";

const { Title } = Typography;

const Login: React.FC = () => {
  const user = useAuth();

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      message.success("Successfully signed in with Google");
      // Redirect or update app state here
    } catch (error) {
      console.error("Error signing in with Google:", error);
      message.error("Failed to sign in with Google");
    }
  };

  useEffect(() => {
    if (user?.user?.isAnonymous) {
      user.logout();
    }
  }, [user]);

  if (user.user?.emailVerified) {
    return <Navigate to="/" replace />;
  }

  return (
    <div style={styles.container}>
      <Card style={styles.card}>
        <Title level={2} style={styles.title}>
          Welcome
        </Title>
        <Button
          icon={<GoogleOutlined />}
          size="large"
          style={styles.googleButton}
          onClick={handleGoogleLogin}
        >
          Sign in with Google
        </Button>
      </Card>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f2f5",
  },
  card: {
    width: "100%",
    maxWidth: "400px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  title: {
    textAlign: "center" as const,
    marginBottom: "24px",
    color: "#1890ff",
  },
  googleButton: {
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: 500,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.08)",
    transition: "all 0.3s",
  },
};

export default Login;
