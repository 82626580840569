import React from "react";
import { Modal, Button, Typography, message } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../libs/firebase";
import colors from "../../styles/colors";
const { Title, Text } = Typography;

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      message.success("Successfully signed in with Google");
      onClose();
    } catch (error) {
      console.error("Error signing in with Google:", error);
      message.error("Failed to sign in with Google");
    }
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={onClose}
      width={400}
      centered
      closeIcon={<span style={styles.closeButton}>×</span>}
    >
      <div style={styles.container}>
        <div style={styles.headerSection}>
          <Title level={3} style={styles.title}>
            Create Your Magic ✨
          </Title>
          <Text style={styles.subtitle}>
            One simple login, three powerful benefits
          </Text>
        </div>

        <div style={styles.benefitsSection}>
          <div style={styles.benefitItem}>
            <div style={styles.benefitIcon}>🎨</div>
            <div style={styles.benefitText}>Create custom writing styles</div>
          </div>
          <div style={styles.benefitItem}>
            <div style={styles.benefitIcon}>📝</div>
            <div style={styles.benefitText}>Design personalized formats</div>
          </div>
          <div style={styles.benefitItem}>
            <div style={styles.benefitIcon}>🤖</div>
            <div style={styles.benefitText}>Get tailored AI assistance</div>
          </div>
        </div>

        <div style={styles.actionSection}>
          <Button
            icon={<GoogleOutlined />}
            size="large"
            type="primary"
            style={styles.googleButton}
            onClick={handleGoogleLogin}
          >
            Continue with Google
          </Button>
          <Text style={styles.privacyText}>
            We respect your privacy. No spam, ever.
          </Text>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "24px",
    padding: "8px 0",
  },
  headerSection: {
    textAlign: "center" as const,
    marginBottom: "8px",
  },
  title: {
    color: colors.primary.main,
    marginBottom: "8px",
  },
  subtitle: {
    fontSize: "16px",
    color: "#666",
  },
  benefitsSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as const,
    gap: "16px",
    marginBottom: "8px",
  },
  benefitItem: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  benefitIcon: {
    fontSize: "20px",
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f0f7ff",
    borderRadius: "50%",
  },
  benefitText: {
    fontSize: "15px",
    color: "#333",
  },
  actionSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "12px",
  },
  googleButton: {
    width: "100%",
    fontWeight: 500,
  },
  privacyText: {
    fontSize: "13px",
    color: "#999",
  },
  closeButton: {
    fontSize: "20px",
    color: "#999",
  },
};

export default LoginModal;
