import React from "react";
import { List, Typography, Tooltip } from "antd";
import { ImprovementOption } from "../../libs/types";
import { diffWords } from "diff";
import { useAuth } from "../../hooks/useAuth";
interface ImprovementOptionsProps {
  options: ImprovementOption[];
  onSelect: (option: string) => void;
  selectedText?: string;
}

const ImprovementOptions: React.FC<ImprovementOptionsProps> = ({
  options,
  onSelect,
  selectedText = "",
}) => {
  const { user } = useAuth();
  const renderDiff = (text: string) => {
    if (!selectedText) return <Typography.Text>{text}</Typography.Text>;

    const differences = diffWords(selectedText, text);

    return (
      <Typography.Text>
        {differences.map((part, i) => (
          <span
            key={i}
            className={user?.emailVerified ? "sensitive" : ""}
            style={{
              backgroundColor: part.added
                ? "#f6ffed"
                : part.removed
                ? "#fff1f0"
                : "transparent",
              textDecoration: part.removed ? "line-through" : "none",
              color: part.removed
                ? "#ff4d4f"
                : part.added
                ? "#52c41a"
                : "inherit",
            }}
          >
            {part.value}
          </span>
        ))}
      </Typography.Text>
    );
  };

  return (
    <List
      size="small"
      dataSource={options}
      renderItem={(option) => (
        <Tooltip
          title={
            <span className={user?.emailVerified ? "sensitive" : ""}>
              {option.description}
            </span>
          }
          placement="right"
        >
          <List.Item
            onClick={() => onSelect(option.text)}
            className="improvement-option-item"
            style={{
              cursor: "pointer",
              transition: "all 0.2s ease",
            }}
            onMouseEnter={(e) => {
              const target = e.currentTarget;
              target.style.backgroundColor = "#fafafa";
            }}
            onMouseLeave={(e) => {
              const target = e.currentTarget;
              target.style.backgroundColor = "transparent";
            }}
          >
            {renderDiff(option.text)}
          </List.Item>
        </Tooltip>
      )}
      style={{
        maxWidth: "500px",
        maxHeight: "400px",
        overflowY: "auto",
        backgroundColor: "#fff",
        border: "1px solid #f0f0f0",
        borderRadius: "4px",
      }}
    />
  );
};

export default ImprovementOptions;
