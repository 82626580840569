import { useCallback, useState } from "react";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../libs/firebase";
import { useAuth } from "./useAuth";

export const useDeleteTemplate = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const deleteTemplate = useCallback(
    async (templateId: string) => {
      setIsLoading(true);
      setError(null);

      try {
        await deleteDoc(doc(db, `users/${user?.uid}/templates`, templateId));
        setIsLoading(false);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred")
        );
        setIsLoading(false);
        throw err;
      }
    },
    [user]
  );

  return { deleteTemplate, isLoading, error };
};
