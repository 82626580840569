import React from "react";
import { Editor } from "@tiptap/react";
import { Button } from "antd";
import { UndoOutlined, RedoOutlined } from "@ant-design/icons";
import { DesktopTooltip } from "../DesktopTooltip";
interface Props {
  editor: Editor;
  style?: React.CSSProperties;
  collapsed?: boolean;
}

const MenuBar = ({ editor, style, collapsed }: Props) => {
  if (!editor) {
    return null;
  }

  return (
    <div style={{ ...style, display: "flex" }}>
      <DesktopTooltip title="Undo">
        <Button
          icon={<UndoOutlined />}
          style={{
            flex: 1,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          onClick={() => editor.chain().focus().undo().run()}
        >
          {collapsed ? "" : "Undo"}
        </Button>
      </DesktopTooltip>
      <DesktopTooltip title="Redo">
        <Button
          icon={<RedoOutlined />}
          style={{
            flex: 1,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onClick={() => editor.chain().focus().redo().run()}
        >
          {collapsed ? "" : "Redo"}
        </Button>
      </DesktopTooltip>
    </div>
  );
};

export default MenuBar;
