import { Button, Select, Typography } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { useState } from "react";
import { TemplateItem } from "../../libs/types";
import { DesktopTooltip } from "../DesktopTooltip";

interface SelectorProps {
  collapsed: boolean;
  items: TemplateItem[];
  selectedIndex: number;
  onSelect: (index: number) => void;
  onEdit?: (index: number) => void;
  onDelete?: (index: number) => void;
  title: string;
}

export function Selector({
  collapsed,
  items,
  selectedIndex,
  onSelect,
  onEdit,
  onDelete,
  title,
}: SelectorProps) {
  const [isHovered, setIsHovered] = useState<number | null>(null);

  return (
    <div style={styles.container}>
      <Typography.Text strong style={styles.title}>
        {title}
      </Typography.Text>
      <Select
        style={styles.selector}
        popupMatchSelectWidth={false}
        value={selectedIndex}
        onChange={onSelect}
        aria-label="Item selector"
        optionLabelProp="label"
        suffixIcon={collapsed ? null : undefined}
        size="middle"
      >
        {items.map((item, index) => (
          <Select.Option
            key={index}
            value={index}
            label={`${item.emoji} ${item.title}`}
            style={item.id === "add" ? styles.addCustomOption : undefined}
            onMouseEnter={() => setIsHovered(index)}
            onMouseLeave={() => setIsHovered(-1)}
          >
            <div style={styles.optionContent}>
              <DesktopTooltip title={item.description} placement="right">
                <div style={styles.optionMain}>
                  {`${item.emoji} ${item.title}`}
                  {item.isCustom && (
                    <div
                      style={{
                        ...styles.actionButtons,
                        opacity: isHovered === index ? 1 : 0,
                      }}
                    >
                      {onEdit && (
                        <Button
                          type="text"
                          onClick={(e) => {
                            e.stopPropagation();
                            onEdit(index);
                          }}
                          style={styles.actionButton}
                        >
                          <EditFilled style={{ color: "gray" }} />
                        </Button>
                      )}
                      {onDelete && (
                        <Button
                          type="text"
                          onClick={(e) => {
                            e.stopPropagation();
                            onDelete(index);
                          }}
                          style={styles.actionButton}
                        >
                          <DeleteFilled style={{ color: "gray" }} />
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </DesktopTooltip>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

interface Styles {
  container: React.CSSProperties;
  selector: React.CSSProperties;
  title: React.CSSProperties;
  optionContent: React.CSSProperties;
  optionMain: React.CSSProperties;
  optionDescription: React.CSSProperties;
  addCustomOption: React.CSSProperties;
  labelContainer: React.CSSProperties;
  labelText: React.CSSProperties;
  optionText: React.CSSProperties;
  actionButtons: React.CSSProperties;
  actionButton: React.CSSProperties;
}

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    padding: "4px 16px",
    marginBottom: "0.8em",
  },
  selector: {
    maxWidth: "300px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  },
  title: {
    color: "#555",
    marginBottom: "2px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  optionContent: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  optionMain: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  labelText: {
    fontWeight: 500,
  },
  optionText: {
    flex: 1,
  },
  actionButtons: {
    position: "absolute",
    right: 0,
    display: "flex",
    opacity: 0,
    transition: "opacity 0.2s",
    zIndex: 1000,
    backgroundColor: "white",
    borderRadius: "4px",
  },
  actionButton: {
    padding: "4px 4px",
    width: "24px",
    height: "24px",
  },
  optionDescription: {
    fontSize: "0.85em",
    color: "#666",
    marginTop: 4,
  },
  addCustomOption: {
    borderTop: "1px solid #f0f0f0",
    marginTop: "4px",
    paddingTop: "4px",
  },
} as const;
