import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Space,
  Typography,
  Button,
  Popover,
  message,
} from "antd";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { TemplateItem, TemplateType } from "../../libs/types";
import { useCreateTemplate } from "../../hooks/useCreateTemplate";
import { useUpdateTemplate } from "../../hooks/useUpdateTemplate";

function createEmptyTemplate(type: TemplateType): TemplateItem {
  return {
    id: "",
    emoji: "📄",
    title: "",
    description: "",
    type,
  };
}

interface CreateTemplateModalProps {
  title?: string;
  isVisible: boolean;
  onSuccess?: () => void;
  onCancel: () => void;
  templateType?: TemplateType;
  template?: TemplateItem;
}

const CreateEditTemplateModal: React.FC<CreateTemplateModalProps> = ({
  title = "Create Template",
  isVisible,
  onSuccess,
  onCancel,
  templateType = "default",
  template,
}) => {
  const { createTemplate, isLoading, error } = useCreateTemplate();
  const { updateTemplate } = useUpdateTemplate();
  const [draftTemplate, setDraftTemplate] = useState<TemplateItem>(
    createEmptyTemplate(templateType)
  );
  useEffect(() => {
    setDraftTemplate(
      template
        ? { ...template, type: templateType }
        : createEmptyTemplate(templateType)
    );
  }, [template, templateType]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      draftTemplate.title.trim() !== "" &&
        draftTemplate.description.trim() !== ""
    );
  }, [draftTemplate.title, draftTemplate.description]);

  const handleOk = async () => {
    if (!isFormValid) return;

    try {
      if (template) {
        await updateTemplate(draftTemplate);
      } else {
        await createTemplate(draftTemplate);
      }
      onSuccess?.();
      setDraftTemplate(createEmptyTemplate(templateType));
    } catch (err) {
      message.error("Failed to create" + title);
      console.error("Failed to create template:", err);
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    setDraftTemplate((prev) => ({ ...prev, emoji: emojiData.emoji }));
  };

  const EmojiSelector = <EmojiPicker onEmojiClick={handleEmojiClick} />;

  return (
    <Modal
      title={`${template ? "Edit" : "Create"} ${title}`}
      open={isVisible}
      onOk={handleOk}
      onCancel={onCancel}
      okButtonProps={{
        disabled: !isFormValid || isLoading,
        loading: isLoading,
      }}
      okText={template ? "Save" : "Create"}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space align="center">
          <Popover
            content={EmojiSelector}
            trigger="click"
            placement="bottomLeft"
          >
            <Button
              type="text"
              style={{ fontSize: 20, height: "auto", padding: "0 8px" }}
            >
              {draftTemplate.emoji}
            </Button>
          </Popover>
          <Input
            placeholder="Name"
            value={draftTemplate.title}
            onChange={(e) =>
              setDraftTemplate((prev) => ({ ...prev, title: e.target.value }))
            }
            style={{ fontSize: 16 }}
          />
        </Space>
        <Typography.Text>Description</Typography.Text>
        <Input.TextArea
          placeholder={
            templateType === "writing-style"
              ? `What style you want your content to be written in. For example:
- Write it in a friendly and professional way
- Use simple language that everyone can understand`
              : `What format you want your content to be written in. For example:
- Structure it as an email with greeting and signature
- Marketing campaign newsletter with a limit of 3 paragraphs`
          }
          value={draftTemplate.description}
          onChange={(e) =>
            setDraftTemplate((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
          rows={4}
        />
        {error && (
          <Typography.Text type="danger">{error.message}</Typography.Text>
        )}
      </Space>
    </Modal>
  );
};

export default CreateEditTemplateModal;
