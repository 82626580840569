import React, { useMemo } from "react";
import { Card, Typography, Tooltip, List } from "antd";
import {
  MinusOutlined,
  UpOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import colors from "../../styles/colors";
import { useAuth } from "../../hooks/useAuth";

const { Text } = Typography;

interface ContextAnalysisProps {
  isLoading: boolean;
  actionItems?: Array<{
    id: string;
    text: string;
    priority: "high" | "medium" | "low";
    context: string;
  }>;
  actionItemOverrides: Record<string, boolean>;
  onActionItemToggle?: (id: string, completed: boolean) => void;
  completedActionItems: Record<string, string>;
}

const priorityIcons = {
  high: <UpOutlined />,
  medium: <MinusOutlined />,
  low: <DownOutlined />,
};

const ContextAnalysis: React.FC<ContextAnalysisProps> = ({
  isLoading,
  actionItems: actionItemsProp,
  onActionItemToggle,
  actionItemOverrides,
  completedActionItems,
}) => {
  const { user } = useAuth();

  const getTooltipContent = (
    item: {
      id: string;
      text: string;
      priority: "high" | "medium" | "low";
      context: string;
    },
    isChecked: boolean
  ) => {
    return (
      <div>
        <div>Context: {item.context}</div>
        {isChecked && (
          <div>
            {item.id in actionItemOverrides
              ? `Status: Manually marked as ${
                  actionItemOverrides[item.id] ? "completed" : "in progress"
                }`
              : item.id in completedActionItems
              ? `Status: ${completedActionItems[item.id]}`
              : null}
          </div>
        )}
      </div>
    );
  };

  const actionItems = useMemo(() => {
    return actionItemsProp
      ?.map((item) => {
        const isChecked =
          item.id in actionItemOverrides
            ? actionItemOverrides[item.id]
            : item.id in completedActionItems
            ? true
            : false;
        return {
          ...item,
          completed: isChecked,
        };
      })
      .sort((a, b) => {
        if (a.completed !== b.completed) {
          return a.completed ? 1 : -1;
        }
        if (a.priority !== b.priority) {
          if (a.priority === "high") {
            return -1;
          }
          if (b.priority === "high") {
            return 1;
          }
          if (a.priority === "medium") {
            return -1;
          }
          if (b.priority === "medium") {
            return 1;
          }
          return 0;
        }
        if (a.text !== b.text) {
          return a.text.localeCompare(b.text);
        }
        return 0;
      });
  }, [actionItemsProp, actionItemOverrides, completedActionItems]);

  return (
    <Card size="small" style={styles.card}>
      {isLoading && (
        <LoadingOutlined
          style={{ position: "absolute", top: 3, left: 3 }}
          spin
        />
      )}
      {!actionItems ? (
        <Text type="secondary" style={styles.emptyState}>
          📝 Add email threads or details to discover action items!
        </Text>
      ) : actionItems.length === 0 ? (
        <Text type="secondary" style={styles.emptyState}>
          No action items found
        </Text>
      ) : (
        <List
          size="small"
          dataSource={actionItems}
          renderItem={(item) => {
            return (
              <Tooltip
                key={item.id}
                title={getTooltipContent(item, item.completed)}
                placement="right"
              >
                <List.Item
                  onClick={() => onActionItemToggle?.(item.id, !item.completed)}
                  style={styles.actionItem}
                >
                  <Text
                    className={user?.emailVerified ? "sensitive" : ""}
                    style={item.completed ? styles.completedItem : undefined}
                  >
                    {item.text}
                  </Text>
                  <span style={{ color: priorityColors[item.priority] }}>
                    {priorityIcons[item.priority]}
                  </span>
                </List.Item>
              </Tooltip>
            );
          }}
        />
      )}
    </Card>
  );
};

const priorityColors = {
  high: "red",
  medium: "orange",
  low: "green",
};

const styles: Record<string, React.CSSProperties> = {
  card: {
    borderRadius: "8px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  title: {
    margin: 0,
    fontSize: "0.9em",
    flexGrow: 1,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  actionItemsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  actionItemsTitle: {
    fontSize: "14px",
    color: colors.primary.main,
  },
  actionItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    padding: "4px 8px",
  },
  completedItem: {
    textDecoration: "line-through",
    color: colors.neutral.gray,
  },
  emptyState: {
    fontSize: "0.9em",
  },
  contextSection: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  contextContent: {
    padding: "12px",
    backgroundColor: colors.background.default,
    borderRadius: "4px",
    fontSize: "0.9em",
  },
  sectionTitle: {
    marginBottom: "8px",
  },
  priorityIcon: {
    fontSize: "12px",
    width: "12px",
    display: "inline-block",
  },
};

export default ContextAnalysis;
