import { useDocument as useFirebaseDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { db } from "../libs/firebase";
import { useMemo } from "react";
import packageJson from "../../package.json";

type ReloadInfo = "none" | "reload" | "urgent-reload";

export function useVersionCheck(): ReloadInfo {
  const [snapshot] = useFirebaseDocument(doc(db, `metadata/version`));

  const reloadInfo = useMemo(() => {
    if (!snapshot?.exists()) {
      console.log("No version found");
      return "none";
    }

    const lastVersionServer = snapshot.data()?.lastVersion;
    if (!lastVersionServer) {
      console.log("No version found");
      return "none";
    }

    const lastVersion = lastVersionServer.split(".").map(Number);
    const currentVersion = packageJson.version.split(".").map(Number);

    // Major version is different 1.0.0 -> 2.0.0
    if (lastVersion[0] > currentVersion[0]) {
      return "urgent-reload";
    } else if (
      // Minor version is different 1.0.0 -> 1.1.0
      lastVersion[0] === currentVersion[0] &&
      lastVersion[1] > currentVersion[1]
    ) {
      return "reload";
    }

    // Patch version is different 1.0.0 -> 1.0.1
    return "none";
  }, [snapshot]);

  return reloadInfo;
}
