import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Button,
  Divider,
  Layout,
  Typography,
  Input,
  message,
  Popover,
  Spin,
} from "antd";
import { PiMagicWandFill as MagicWand } from "react-icons/pi";
import { BsRobot } from "react-icons/bs";
import { motion } from "framer-motion";

import MarkdownEditor from "./editor/MarkdownEditor";
import {
  improveTone,
  improveTextSelection,
  processAudioFromDemo,
  sendChatMessage,
} from "../libs/api";
import { Selector } from "./demo/Selector";
import Sider from "antd/es/layout/Sider";
import colors from "../styles/colors";
import logo from "../logo.png";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LoadingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { getAuth, signInAnonymously } from "firebase/auth";
import { useAuth } from "../hooks/useAuth";
import { useMediaQuery } from "react-responsive";
import ImprovementOptions from "./demo/ImprovementOptions";
import { ImprovementOption, TemplateItem, TemplateType } from "../libs/types";
import { Tone } from "../libs/types";
import { FeedbackModal } from "./demo/FeedbackModal";
import { useInactivityReset } from "../hooks/useInactivityReset";
import { useTemplateList } from "../hooks/useTemplateList";
import { useDeleteTemplate } from "../hooks/useDeleteTemplate";
import CreateEditTemplateModal from "./template/CreateEditTemplateModal";
import ChatModal from "./demo/ChatModal";
import { ChatMessage } from "../libs/types";
import LoginModal from "./auth/LoginModal";
import ThemeProvider from "../styles/ThemeProvider";
const { Title } = Typography;

const ONBOARDING_CONTENT = `Not interested Tony. I have a job.

Thor`;

const ONBOARDING_CONTEXT = `From: <tony.stark@starkindustries.com>
To: <thor.odinson@asgard.com>
Subject: Welcome to the Avengers Initiative

Hello Thor,

Following our recent battle in New York, I'd like to formally offer you a position on our team. Your combat skills and lightning abilities would be a valuable asset. I hope you'll consider it.

Best regards,
Tony Stark
CEO, Stark Industries`;

const CONTENT_FORMATS: TemplateItem[] = [
  {
    id: "email",
    type: "content-format",
    title: "Email",
    emoji: "📧",
    description:
      "Standard email body format with greeting, body, and signature",
    contextTitle: "Email thread",
    contextPlaceholder:
      "Add helpful context:\n- Paste previous email thread (if applicable)\n- Who is the recipient?\n- What is your message about?\n- What outcome do you want?",
  },
  {
    id: "document",
    type: "content-format",
    title: "Document",
    emoji: "📄",
    description:
      "Structured document like reports, or articles with clear sections",
    contextTitle: "Document background",
    contextPlaceholder:
      "Share the background:\n- What's this document about?\n- Who will read it?\n- What should it include?",
  },
  {
    id: "message",
    type: "content-format",
    title: "Message",
    emoji: "💬",
    description: "Short and simple message",
    contextTitle: "Message context",
    contextPlaceholder:
      "Please provide context:\n- What is this message about?\n- Who is the intended audience?\n- What key points need to be included?",
  },
];

const STYLE_TYPES: TemplateItem[] = [
  {
    id: "friendly",
    type: "writing-style",
    title: "Friendly",
    emoji: "🙂",
    description:
      "Warm and personable, may include emojis and casual expressions",
    isCustom: false,
  },
  {
    id: "professional",
    type: "writing-style",
    title: "Professional",
    emoji: "👔",
    description: "Business casual - friendly yet professional",
    isCustom: false,
  },
  {
    id: "formal",
    type: "writing-style",
    title: "Formal",
    emoji: "💼",
    description: "Highly structured and traditional business writing",
    isCustom: false,
  },
];

const CUSTOM_STYLE_OPTION: TemplateItem = {
  id: "add",
  type: "writing-style",
  title: "Add Custom",
  emoji: "➕",
  description: "Create your own writing style",
  isCustom: false,
};

const CUSTOM_FORMAT_OPTION: TemplateItem = {
  id: "add",
  type: "content-format",
  title: "Add Custom",
  emoji: "➕",
  description: "Create your own content format",
  isCustom: false,
};

function Demo() {
  const { user } = useAuth();
  const [localContent, setLocalContent] = useState("");
  const [selection, setSelection] = useState("");
  const [selectionPosition, setSelectionPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [editTemplate, setEditTemplate] = useState<TemplateItem>();
  const { deleteTemplate } = useDeleteTemplate();

  const [loadingButton, setLoadingButton] = useState<
    "record" | "improve" | "alternatives" | null
  >(null);

  const [selectedContentFormat, setSelectedContentFormat] = useState(() => {
    return parseInt(localStorage.getItem("selectedContentFormat") || "0");
  });
  const [selectedStyleType, setSelectedStyleType] = useState(() => {
    return parseInt(localStorage.getItem("selectedStyleType") || "1");
  });

  useEffect(() => {
    localStorage.setItem(
      "selectedContentFormat",
      selectedContentFormat.toString()
    );
  }, [selectedContentFormat]);

  useEffect(() => {
    localStorage.setItem("selectedStyleType", selectedStyleType.toString());
  }, [selectedStyleType]);

  const [collapsedSider, setCollapsedSider] = useState(false);
  const [isSiderHovered, setIsSiderHovered] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [contextInput, setContextInput] = useState("");

  const [completedOnboarding, setCompletedOnboarding] = useState(
    localStorage.getItem("completedOnboarding") != null
  );
  const [showOnboarding, setShowOnboarding] = useState(false);
  useEffect(() => {
    if (completedOnboarding || user?.emailVerified) {
      localStorage.setItem(
        "completedOnboarding",
        completedOnboarding.toString()
      );
      setShowOnboarding(false);
    } else {
      setLocalContent(ONBOARDING_CONTENT);
      setContextInput(ONBOARDING_CONTEXT);
      setShowOnboarding(true);
    }
  }, [completedOnboarding]);

  const resetContent = useCallback(() => {
    setLocalContent("");
    setSelection("");
    setContextInput("");
    setChatMessages([]);
    setIsChatModalVisible(false);
  }, []);

  useInactivityReset(resetContent, 0.5);

  useEffect(() => {
    setCollapsedSider(isMobile);
  }, [isMobile]);
  useEffect(() => {
    if (showOnboarding) {
      setCollapsedSider(false);
    }
  }, [showOnboarding]);

  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    if (!auth.currentUser) {
      signInAnonymously(auth)
        .then(() => console.log("AL successful"))
        .catch((error) => console.error("AL failed:", error));
    } else {
      console.log("AL exists");
    }
  }, []);

  const handlePolish = async () => {
    setCompletedOnboarding(true);
    setLoadingButton("improve");
    const userNameContext = user?.displayName
      ? `USER_NAME: ${user.displayName}\n`
      : "";
    try {
      let { content: updatedContent } = await processAudioFromDemo(
        undefined,
        allFormats[selectedContentFormat].description,
        allStyles[selectedStyleType].description,
        userNameContext + contextInput,
        localContent,
        selection
      );

      if (updatedContent) {
        if (selection) {
          updatedContent = localContent.replace(selection, updatedContent);
        }
        setLocalContent(updatedContent);
      }
    } catch (error) {
      message.error("Error processing your request");
    } finally {
      setLoadingButton(null);
    }
  };

  const handleImproveTone = async (tone: Tone) => {
    setLoadingButton("improve");
    try {
      let { content: updatedContent } = await improveTone(
        tone.description,
        allFormats[selectedContentFormat].description,
        allStyles[selectedStyleType].description,
        localContent
      );

      if (updatedContent) {
        setLocalContent(updatedContent);
      }
    } catch (error) {
      message.error("Error processing your request");
    } finally {
      setLoadingButton(null);
    }
  };

  const [improvementOptions, setImprovementOptions] = useState<
    ImprovementOption[]
  >([]);

  const handleSelectionPolish = async () => {
    setLoadingButton("alternatives");
    try {
      const { options } = await improveTextSelection(
        allFormats[selectedContentFormat].description,
        allStyles[selectedStyleType].description,
        localContent,
        selection
      );
      setImprovementOptions(options);
    } catch (error) {
      message.error("Error processing your request");
    } finally {
      setLoadingButton(null);
    }
  };

  const handleOptionSelect = (option: string) => {
    const updatedContent = localContent.replace(selection, option);
    setLocalContent(updatedContent);
    setSelection("");
    setSelectionPosition(null);
  };

  const toggleSider = () => {
    setCollapsedSider(!collapsedSider);
  };

  const handleSelectionChange = (newSelection: string) => {
    setSelection(newSelection);
    setImprovementOptions([]);

    if (newSelection) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        // Calculate position below the selection
        setSelectionPosition({
          x: rect.left + 16 + window.scrollX,
          y: rect.bottom + window.scrollY + 8, // Add 8px padding below selection
        });
      }
    }
  };

  const isLoading = loadingButton != null;

  const [customModalOpenType, setCustomModalOpenType] =
    useState<TemplateType>();

  // Templates
  const { templates, loading: loadingTemplates } = useTemplateList();

  // Styles
  const [customStyles, setCustomStyles] = useState<TemplateItem[]>([]);
  useEffect(() => {
    if (!loadingTemplates && templates) {
      const writingStyleTemplates: TemplateItem[] = templates
        .filter((template) => template.type === "writing-style")
        .map((template) => ({ ...template, isCustom: true }));
      setCustomStyles(writingStyleTemplates);
    }
  }, [templates, loadingTemplates]);
  const allStyles = useMemo(
    () => [...STYLE_TYPES, ...customStyles, CUSTOM_STYLE_OPTION],
    [customStyles]
  );

  // Formats
  const [customFormats, setCustomFormats] = useState<TemplateItem[]>([]);
  useEffect(() => {
    if (!loadingTemplates && templates) {
      const contentFormatTemplates: TemplateItem[] = templates
        .filter((template) => template.type === "content-format")
        .map((template) => ({ ...template, isCustom: true }));
      setCustomFormats(contentFormatTemplates);
    }
  }, [templates, loadingTemplates]);
  const allFormats = useMemo(
    () => [...CONTENT_FORMATS, ...customFormats, CUSTOM_FORMAT_OPTION],
    [customFormats]
  );

  const [isChatModalVisible, setIsChatModalVisible] = useState(false);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [isChatLoading, setIsChatLoading] = useState(false);

  const handleOpenChat = () => {
    if (!user || user.isAnonymous) {
      setIsLoginModalVisible(true);
    } else {
      setIsChatModalVisible(true);
    }
  };

  const handleToggleChat = () => {
    setIsChatModalVisible((prev) => !prev);
  };

  const handleDeleteChatMessage = () => {
    setChatMessages([]);
  };

  const handleSendChatMessage = async (userMessage: string) => {
    setIsChatLoading(true);

    // Add user message to chat
    const chatUserMessage: ChatMessage = {
      id: Date.now().toString(),
      content: localContent,
      message: userMessage,
      contextInput: contextInput,
      role: "user",
      timestamp: new Date(),
    };

    setChatMessages((prev) => [...prev, chatUserMessage]);

    try {
      // Get AI response
      const { message, updatedContent: updatedLocalContent } =
        await sendChatMessage(
          chatMessages,
          chatUserMessage,
          allFormats[selectedContentFormat].description,
          allStyles[selectedStyleType].description
        );

      // Add AI response to chat
      const aiMessage: ChatMessage = {
        id: (Date.now() + 1).toString(),
        message: message,
        content: updatedLocalContent,
        role: "assistant",
        timestamp: new Date(),
      };

      setChatMessages((prev) => [...prev, aiMessage]);
      console.log(chatMessages, aiMessage);
      if (updatedLocalContent) {
        setLocalContent(updatedLocalContent);
      }
    } catch (error) {
      message.error("Error getting response from AI");
    } finally {
      setIsChatLoading(false);
    }
  };

  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const handleTemplateSelect = (type: TemplateType, index: number) => {
    if (type === "writing-style") {
      if (allStyles[index].id === "add") {
        if (!user || user.isAnonymous) {
          setIsLoginModalVisible(true);
        } else {
          setCustomModalOpenType(type);
        }
      } else {
        setSelectedStyleType(index);
      }
    } else if (type === "content-format") {
      if (allFormats[index].id === "add") {
        if (!user || user.isAnonymous) {
          setIsLoginModalVisible(true);
        } else {
          setCustomModalOpenType(type);
        }
      } else {
        setSelectedContentFormat(index);
      }
    }
  };

  // Add render of onboarding pointer when showOnboarding is true
  const renderOnboardingPointer = () => {
    if (!showOnboarding) return null;

    return (
      <motion.div
        style={styles.onboardingPointer}
        initial={{ y: 10 }}
        animate={{ y: -10 }}
        transition={{
          repeat: Infinity,
          repeatType: "reverse",
          duration: 0.8,
        }}
      >
        <div style={styles.onboardingMessage}>
          <Typography.Text>
            Click "<b>Improve</b>" to make Thor's reply professional!
          </Typography.Text>
        </div>
        <div style={styles.onboardingArrow}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
              fill={colors.primary.main}
            />
          </svg>
        </div>
      </motion.div>
    );
  };

  if (loadingTemplates) {
    return (
      <ThemeProvider>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <Layout style={styles.layout}>
      <Sider
        width={200}
        theme="light"
        collapsed={collapsedSider}
        collapsible
        trigger={null}
        style={styles.sider}
        onMouseEnter={() => setIsSiderHovered(true)}
        onMouseLeave={() => setIsSiderHovered(false)}
      >
        <div style={styles.siderContainer}>
          <div style={styles.brandContainer}>
            <img src={logo} alt="Write AI Logo" style={styles.brandLogo} />
            {!collapsedSider && (
              <Title level={5} style={styles.brandTitle}>
                Write AI
              </Title>
            )}
          </div>

          <Divider style={{ margin: "16px 0" }} />

          <Selector
            collapsed={collapsedSider}
            items={allStyles}
            selectedIndex={selectedStyleType}
            onSelect={(index) => handleTemplateSelect("writing-style", index)}
            onEdit={(index) => {
              setEditTemplate(allStyles[index]);
              setCustomModalOpenType("writing-style");
            }}
            onDelete={(index) => {
              switch (customModalOpenType) {
                case "writing-style":
                  if (allStyles[index].isCustom) {
                    deleteTemplate(allStyles[index].id);
                    setSelectedStyleType(selectedStyleType - 1);
                  }
                  break;
                case "content-format":
                  if (allFormats[index].isCustom) {
                    deleteTemplate(allFormats[index].id);
                    setSelectedContentFormat(selectedContentFormat - 1);
                  }
                  break;
              }
            }}
            title={"Style"}
          />
          <Selector
            collapsed={collapsedSider}
            items={allFormats}
            selectedIndex={selectedContentFormat}
            onSelect={(index) => handleTemplateSelect("content-format", index)}
            onEdit={(index) => {
              setEditTemplate(allFormats[index]);
              setCustomModalOpenType("content-format");
            }}
            onDelete={(index) => {
              if (allFormats[index].isCustom) {
                deleteTemplate(allFormats[index].id);
                setSelectedContentFormat(selectedContentFormat - 1);
              }
            }}
            title={"Format"}
          />

          {!collapsedSider && (
            <div style={styles.container}>
              <Typography.Text strong style={styles.title}>
                {selectedContentFormat < CONTENT_FORMATS.length
                  ? allFormats[selectedContentFormat].contextTitle
                  : "Context"}
              </Typography.Text>
              <Input.TextArea
                value={contextInput}
                onChange={(e) => setContextInput(e.target.value)}
                placeholder={
                  selectedContentFormat < CONTENT_FORMATS.length
                    ? allFormats[selectedContentFormat].contextPlaceholder
                    : "(Optional) Add background info for a better result:\n- Who's it for?\n- What's the goal?\n- Key details?"
                }
                style={styles.customInstructions}
                rows={10}
              />
            </div>
          )}

          <Button
            type="text"
            icon={
              collapsedSider ? <DoubleRightOutlined /> : <DoubleLeftOutlined />
            }
            onClick={toggleSider}
            style={{
              ...styles.collapseButton,
              opacity: isMobile || isSiderHovered ? 1 : 0,
            }}
          />
        </div>
      </Sider>
      <Layout style={styles.contentLayout}>
        <Layout.Content style={styles.content}>
          <div style={styles.editorContainer}>
            <MarkdownEditor
              value={localContent}
              collapsed={!collapsedSider}
              onChange={setLocalContent}
              onSelectionChange={handleSelectionChange}
              onImproveTone={handleImproveTone}
              contextInput={contextInput}
              showContextAnalysis={
                allFormats[selectedContentFormat].id === "mail"
              }
            />
          </div>
          <div style={styles.buttonContainer}>
            <div style={styles.buttonsStack}>
              <Button
                type="primary"
                icon={<MagicWand />}
                disabled={!localContent || loadingButton != null}
                loading={loadingButton === "improve"}
                size="large"
                onClick={handlePolish}
              >
                {!isMobile || collapsedSider ? "Improve" : ""}
              </Button>
              <Button
                icon={<BsRobot />}
                size="large"
                onClick={handleOpenChat}
                style={{ color: colors.primary.dark }}
              >
                {!isMobile || collapsedSider ? "Ask AI" : ""}
              </Button>
            </div>
            {renderOnboardingPointer()}
          </div>

          {selection && selectionPosition && (
            <Popover
              open={improvementOptions.length > 0}
              content={
                improvementOptions.length > 0 && (
                  <ImprovementOptions
                    options={improvementOptions}
                    onSelect={handleOptionSelect}
                    selectedText={selection}
                  />
                )
              }
              placement="bottom"
              trigger="click"
            >
              <div
                style={{
                  ...styles.floatingSelectionButtons,
                  top: selectionPosition.y,
                  left: selectionPosition.x,
                  borderColor: colors.primary.main,
                }}
              >
                <Button
                  type="text"
                  icon={
                    loadingButton === "improve" ? (
                      <LoadingOutlined color={colors.primary.main} />
                    ) : (
                      <MagicWand
                        color={isLoading ? "lightgray" : colors.primary.main}
                      />
                    )
                  }
                  size="small"
                  onClick={handlePolish}
                  disabled={isLoading}
                  style={{ borderRadius: "4px 0 0 4px" }}
                >
                  Improve
                </Button>
                <Button
                  type="text"
                  title="Alternatives"
                  icon={
                    loadingButton === "alternatives" ? (
                      <LoadingOutlined color={colors.primary.main} />
                    ) : (
                      <UnorderedListOutlined
                        style={{
                          color: isLoading ? "lightgray" : colors.neutral.black,
                        }}
                      />
                    )
                  }
                  onClick={handleSelectionPolish}
                  disabled={isLoading}
                  size="small"
                  style={{
                    borderRadius: "0",
                  }}
                >
                  Alternatives
                </Button>
              </div>
            </Popover>
          )}
        </Layout.Content>
      </Layout>
      <FeedbackModal
        isOpen={isFeedbackModalVisible}
        onClose={() => setIsFeedbackModalVisible(false)}
      />
      <ChatModal
        isOpen={isChatModalVisible}
        onClose={handleToggleChat}
        onDelete={handleDeleteChatMessage}
        onSendMessage={handleSendChatMessage}
        messages={chatMessages}
        loading={isChatLoading}
      />
      <LoginModal
        isOpen={isLoginModalVisible}
        onClose={() => setIsLoginModalVisible(false)}
      />
      <CreateEditTemplateModal
        title={
          customModalOpenType === "writing-style"
            ? "Style"
            : customModalOpenType === "content-format"
            ? "Format"
            : "Template"
        }
        templateType={customModalOpenType}
        template={editTemplate}
        isVisible={!!customModalOpenType}
        onCancel={() => {
          setCustomModalOpenType(undefined);
          setEditTemplate(undefined);
        }}
        onSuccess={() => {
          setCustomModalOpenType(undefined);
          setEditTemplate(undefined);
          if (!editTemplate) {
            switch (customModalOpenType) {
              case "writing-style":
                setSelectedStyleType(allStyles.length - 1);
                break;
              case "content-format":
                setSelectedContentFormat(allFormats.length - 1);
                break;
            }
          }
        }}
      />
    </Layout>
  );
}

const styles: Record<string, React.CSSProperties> = {
  layout: {
    height: "100vh",
    background: "transparent",
  },
  header: {
    background: "transparent",
    padding: "0px",
  },
  headerContent: {
    display: "flex" as const,
    justifyContent: "space-between" as const,
    alignItems: "center" as const,
    padding: "0px 20px",
    height: "100%",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  sider: {
    zIndex: 1,
    overflow: "visible",
    position: "sticky" as const,
    top: 0,
    left: 0,
    background: colors.background.paper,
  },
  siderContainer: {
    position: "relative" as const,
    height: "100%",
    marginTop: "20px",
    justifyContent: "center",
    paddingBottom: "40px",
  },
  contentLayout: {
    display: "flex",
    height: "100%",
    background: "white",
  },
  content: {
    display: "flex",
    position: "relative",
    flexDirection: "column" as const,
    height: "calc(100% -  40px)",
  },
  editorContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
    margin: "20px",
    border: "1px solid #d9d9d9",
    borderRadius: "4px",
  },
  buttonContainer: {
    marginBottom: "40px",
    display: "flex",
    justifyContent: "center",
    pointerEvents: "none",
    zIndex: 999,
  },
  brandContainer: {
    display: "flex",
    padding: "0px 20px",
    alignItems: "center",
  },
  brandLogo: {
    width: "24px",
    height: "24px",
    marginRight: "12px",
  },
  brandTitle: {
    margin: 0,
    color: colors.primary.main,
    whiteSpace: "nowrap",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "4px 20px",
    marginBottom: "1em",
  },
  title: {
    color: "#666",
  },
  customInstructions: {
    maxWidth: "300px",
    borderRadius: "6px",
    resize: "none",
    backgroundColor: "#ffffff",
    border: `1px solid ${colors.background}`,
  },
  collapseButton: {
    position: "absolute",
    right: "-15px",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: colors.background.default,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 998,
    pointerEvents: "auto",
  },
  buttonsStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px",
    pointerEvents: "auto",
  },
  improveButton: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "0 24px",
    height: "40px",
  },
  floatingSelectionButtons: {
    backgroundColor: "white",
    display: "flex",
    position: "fixed",
    transform: "translate(-50%, 0)", // Center horizontally
    zIndex: 1000,
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid #d9d9d9",
  },
  floatingSelectionButton: {
    width: "32px",
    height: "32px",
    padding: 0,
  },
  contextHelper: {
    fontSize: "12px",
    marginTop: "4px",
  },
  howToButton: {
    margin: "0 16px",
    padding: "4px 16px",
  },
  onboardingPointer: {
    position: "absolute",
    bottom: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    pointerEvents: "auto",
    zIndex: 1000,
  },
  onboardingMessage: {
    background: "white",
    padding: "12px 16px",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    border: `1px solid ${colors.primary.light}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "240px",
    textAlign: "center",
    gap: "4px",
  },
  onboardingArrow: {
    marginTop: "8px",
    transform: "rotate(90deg)",
  },
};

export default Demo;
