import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  EditorEvents,
  useEditor,
  EditorContent,
  mergeAttributes,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import MenuBar from "./MenuBar";
import TurndownService from "turndown";
import { marked } from "marked";
import { Flex } from "antd";
import { Node } from "@tiptap/core";
import Placeholder from "@tiptap/extension-placeholder";
import "./MarkdownEditor.css";
import { debounce } from "lodash";
import ToneAnalysis from "./ToneAnalysis";
import { Tone } from "../../libs/types";
import {
  analyzeActionItems,
  analyzeContext,
  analyzeTone,
} from "../../libs/api";
import ContextAnalysis from "./ContextAnalysis";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../hooks/useAuth";

interface MarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
  onSelectionChange: (selection: string) => void;
  onImproveTone: (tone: Tone) => void;
  contextInput?: string;
  showContextAnalysis?: boolean;
  collapsed?: boolean;
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  value,
  onChange,
  onSelectionChange,
  onImproveTone,
  contextInput,
  showContextAnalysis,
  collapsed,
}) => {
  const { user } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const turndownService = useMemo(
    () => new TurndownService({ headingStyle: "atx" }),
    []
  );

  const extensions = useMemo(
    () => [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
        paragraph: false,
      }),
      Node.create({
        name: "paragraph",
        priority: 1000,
        group: "block",
        content: "inline*",
        parseHTML() {
          return [{ tag: "div" }];
        },
        renderHTML({
          HTMLAttributes,
        }: {
          HTMLAttributes: Record<string, string>;
        }) {
          return [
            "div",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
          ];
        },
      }),
      Placeholder.configure({
        placeholder: "Start typing...",
        emptyEditorClass: "is-editor-empty",
        emptyNodeClass: "is-empty",
        showOnlyWhenEditable: true,
        includeChildren: false,
      }),
    ],
    []
  );

  const debouncedSelectionChange = useMemo(
    () => debounce((text: string) => onSelectionChange?.(text), 300),
    [onSelectionChange]
  );

  const [positiveTones, setPositiveTones] = useState<Tone[]>([]);
  const [negativeTones, setNegativeTones] = useState<Tone[]>([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isAnalyzingContext, setIsAnalyzingContext] = useState(false);
  const [isAnalyzingActionItems, setIsAnalyzingActionItems] = useState(false);
  const [actionItems, setActionItems] = useState<
    {
      id: string;
      text: string;
      priority: "high" | "medium" | "low";
      context: string;
    }[]
  >();
  const [actionItemOverrides, setActionItemOverrides] = useState<
    Record<string, boolean>
  >({});
  const [completedActionItems, setCompletedActionItems] = useState<
    Record<string, string>
  >({});
  const onActionItemToggle = useCallback((id: string, completed: boolean) => {
    setActionItemOverrides((prev) => ({ ...prev, [id]: completed }));
  }, []);

  const debouncedToneAnalysis = useMemo(
    () =>
      debounce(async (text: string) => {
        if (text.trim()) {
          try {
            const { positiveTones, negativeTones } = await analyzeTone(text);
            setPositiveTones(positiveTones);
            setNegativeTones(negativeTones);
          } finally {
            setIsAnalyzing(false);
          }
        } else {
          setPositiveTones([]);
          setNegativeTones([]);
          setIsAnalyzing(false);
        }
      }, 1000),
    []
  );

  const debouncedActionItemAnalysis = useMemo(
    () =>
      debounce(async (text: string) => {
        if (text.trim() && actionItems && actionItems.length > 0) {
          try {
            const { actionItemsCompleted } = await analyzeActionItems(
              text,
              actionItems
            );
            const completedActionItems: Record<string, string> = {};
            Object.keys(actionItemsCompleted).forEach((itemId) => {
              completedActionItems[itemId] =
                actionItemsCompleted[itemId].completedContext;
            });
            setCompletedActionItems(completedActionItems);
          } finally {
            setIsAnalyzingActionItems(false);
          }
        } else {
          setIsAnalyzingActionItems(false);
          setCompletedActionItems({});
        }
      }, 1000),
    [actionItems]
  );
  const debouncedContextAnalysis = useMemo(
    () =>
      debounce(async (text: string) => {
        if (text.trim()) {
          try {
            const analysis = await analyzeContext(text);
            setActionItems(analysis.actionItems);
          } finally {
            setIsAnalyzingContext(false);
          }
        } else {
          setActionItems(undefined);
          setActionItemOverrides({});
          setCompletedActionItems({});
          setIsAnalyzingContext(false);
        }
      }, 1000),
    []
  );

  const handleUpdate = useCallback(
    ({ editor }: EditorEvents["update"]) => {
      const markdown = turndownService.turndown(editor.getHTML());
      onChange(markdown);
    },
    [onChange, turndownService]
  );

  const handleSelectionUpdate = useCallback(
    ({ editor }: EditorEvents["selectionUpdate"]) => {
      const { from, to } = editor.state.selection;
      const selectedText =
        from === to ? "" : editor.state.doc.textBetween(from, to);
      console.log("selectedText", selectedText);

      // If selection is cleared, cancel pending debounced calls and update immediately
      if (!selectedText) {
        debouncedSelectionChange.cancel();
        onSelectionChange("");
      } else {
        debouncedSelectionChange(selectedText);
      }
    },
    [debouncedSelectionChange, onSelectionChange]
  );

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: handleUpdate,
    onSelectionUpdate: handleSelectionUpdate,
    parseOptions: {
      preserveWhitespace: true,
    },
    editorProps: {
      attributes: {
        id: "editor",
        style: "height: 100%; outline: none; border: none;",
      },
    },
    editable: true,
  });

  useEffect(() => {
    if (!editor) {
      return;
    }

    const markdown = turndownService.turndown(editor.getHTML());
    if (markdown !== value) {
      const html = marked(value);
      editor.commands.setContent(html);
    }

    debouncedToneAnalysis.cancel();
    if (value) {
      setIsAnalyzing(true);
      debouncedToneAnalysis(value);
    } else {
      setIsAnalyzing(false);
      setPositiveTones([]);
      setNegativeTones([]);
    }
  }, [editor, value]);

  useEffect(() => {
    if (!editor || !showContextAnalysis) {
      return;
    }

    const markdown = turndownService.turndown(editor.getHTML());
    if (markdown !== value) {
      const html = marked(value);
      editor.commands.setContent(html);
    }

    debouncedActionItemAnalysis.cancel();
    if (value) {
      setIsAnalyzingActionItems(true);
      debouncedActionItemAnalysis(value);
    } else {
      setIsAnalyzingActionItems(false);
    }
  }, [value, actionItems]);

  useEffect(() => {
    if (!editor || !showContextAnalysis) {
      return;
    }

    setIsAnalyzingContext(!!contextInput);
    if (contextInput) {
      debouncedContextAnalysis(contextInput);
    } else {
      setActionItemOverrides({});
      setCompletedActionItems({});
      setActionItems(undefined);
    }
  }, [contextInput]);

  if (!editor) {
    return null;
  }

  return (
    <Flex
      style={{
        ...styles.container,
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {isMobile && (
        <Flex vertical style={styles.toneContainerMobile}>
          <MenuBar
            collapsed={collapsed}
            editor={editor}
            style={styles.menuBarMobile}
          />
          <div style={{ height: "10px" }}></div>
          <ToneAnalysis
            positiveTones={positiveTones}
            negativeTones={negativeTones}
            onImprove={onImproveTone}
            isLoading={isAnalyzing}
          />
          <div style={{ height: "10px" }}></div>
        </Flex>
      )}
      <EditorContent
        editor={editor}
        style={styles.editorContent}
        className={user?.emailVerified ? "sensitive" : ""}
      />
      {!isMobile && (
        <Flex vertical style={styles.toneContainer}>
          <MenuBar editor={editor} style={styles.menuBar} />

          <div style={{ height: "20px" }}></div>
          <ToneAnalysis
            positiveTones={positiveTones}
            negativeTones={negativeTones}
            onImprove={onImproveTone}
            isLoading={isAnalyzing}
          />
          {showContextAnalysis && (
            <>
              <div style={{ height: "20px" }}></div>

              <ContextAnalysis
                isLoading={isAnalyzingContext || isAnalyzingActionItems}
                actionItems={actionItems}
                actionItemOverrides={actionItemOverrides}
                onActionItemToggle={onActionItemToggle}
                completedActionItems={completedActionItems}
              />
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default MarkdownEditor;

const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    width: "100%",
    padding: "20px",
  },
  editorContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "stretch",
    justifyContent: "stretch",
    overflowY: "auto",
    marginTop: "10px",
  },
  toneContainer: {
    width: "200px",
  },
  toneContainerMobile: {
    width: "100%",
  },
  menuBar: {
    width: "200px",
  },
  menuBarMobile: {
    width: "100%",
  },
};
