import posthog from "posthog-js";

posthog.init("phc_XtbLlPRmsjI8XT2wESCkhLW1fY5CEOxfFXOUyHk5mJ1", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always",
  mask_all_text: true,
  mask_all_element_attributes: true,
  session_recording: {
    maskTextSelector: ".sensitive",
    maskTextFn: (text) => {
      // this fn can receive whitespace only text, you might not want to mask that
      if (text.trim().length === 0) {
        return text;
      }

      // A replace any letters with * and numbers with 0
      return text.replace(/[a-zA-Z]/g, "*").replace(/[0-9]/g, "0");
    },
  },
});
