import { useState, useEffect, useCallback } from "react";
import { db } from "../libs/firebase";
import { collection, doc, updateDoc } from "firebase/firestore";
import { Template } from "../libs/types";
import { useAuth } from "./useAuth";

export const useUpdateTemplate = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateTemplate = useCallback(
    async (template: Partial<Template>) => {
      console.log("Updating template:", template);
      setIsLoading(true);
      setError(null);

      try {
        const templateRef = doc(
          collection(db, `users/${user?.uid}/templates`),
          template.id
        );
        await updateDoc(templateRef, {
          ...template,
          updatedAt: new Date(),
        });
        setIsLoading(false);
        return template;
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred")
        );
        setIsLoading(false);
        throw err;
      }
    },
    [user]
  );

  return { updateTemplate, isLoading, error };
};
