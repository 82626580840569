import { useCollectionData } from "react-firebase-hooks/firestore";
import { collection, orderBy, query } from "firebase/firestore";
import { db } from "../libs/firebase";
import { useAuth } from "./useAuth"; // Assume this hook exists to get the current user
import { Template } from "../libs/types";

export const useTemplateList = () => {
  const { user } = useAuth(); // Get the current user

  const templatesQuery = user
    ? query(
        collection(db, `users/${user.uid}/templates`),
        orderBy("createdAt", "asc")
      )
    : null;

  const [templates, loading, error] = useCollectionData(templatesQuery);

  return {
    templates: templates as Template[] | undefined,
    loading,
    error,
  };
};
