import React from "react";
import { Flex, Typography, Progress, Card, Button } from "antd";
import { PiMagicWandFill as MagicWand } from "react-icons/pi";
import { LoadingOutlined } from "@ant-design/icons";
import { Tone } from "../../libs/types";
import { DesktopTooltip } from "../DesktopTooltip";
interface ToneAnalysisProps {
  positiveTones: Tone[];
  negativeTones: Tone[];
  onImprove: (tone: Tone) => void;
  isLoading?: boolean;
}

const TooltipTitle: React.FC<{
  tone: Tone;
  onImprove?: (tone: Tone) => void;
}> = ({ tone, onImprove }) => (
  <div style={styles.tooltipContent}>
    <Flex justify="space-between" align="center">
      <Typography.Text>
        {tone.emoji} <b>{tone.name}</b>
      </Typography.Text>
      {onImprove && (
        <Button
          type="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onImprove(tone);
          }}
          icon={<MagicWand />}
        >
          Improve
        </Button>
      )}
    </Flex>
    <Typography.Text>{tone.description}</Typography.Text>
  </div>
);

const TooltipContent: React.FC<{
  tone: Tone;
  onImprove?: (tone: Tone) => void;
  type: "positive" | "negative";
}> = ({ tone, onImprove, type }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <DesktopTooltip
      title={
        <TooltipTitle
          tone={tone}
          onImprove={
            onImprove
              ? (t) => {
                  onImprove(t);
                  setOpen(false);
                }
              : undefined
          }
        />
      }
      placement="left"
      overlayStyle={styles.tooltip}
      color="white"
      open={open}
      onOpenChange={setOpen}
    >
      <Flex
        gap="4px"
        align="center"
        justify="space-between"
        style={styles.toneRow}
      >
        <Typography.Text
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {tone.emoji} {tone.name}
        </Typography.Text>
        <Progress
          percent={(tone.score / 5) * 100}
          steps={5}
          strokeColor={type === "positive" ? "#52c41a" : "#ff4d4f"}
          showInfo={false}
          size="small"
        />
      </Flex>
    </DesktopTooltip>
  );
};

const ToneAnalysis: React.FC<ToneAnalysisProps> = ({
  positiveTones,
  negativeTones,
  onImprove,
  isLoading = false,
}) => {
  const hasNoTones = positiveTones.length === 0 && negativeTones.length === 0;

  return (
    <Card size="small">
      {isLoading && (
        <LoadingOutlined
          style={{ position: "absolute", top: 3, left: 3 }}
          spin
        />
      )}
      <Flex vertical gap="small" style={styles.content}>
        {hasNoTones ? (
          <Typography.Text type="secondary" style={styles.emptyState}>
            🎯 Start writing to reveal the emotional tones in your message!
          </Typography.Text>
        ) : (
          <>
            {positiveTones
              .sort((a, b) => b.score - a.score)
              .map((tone) => (
                <TooltipContent key={tone.name} tone={tone} type="positive" />
              ))}
            {negativeTones
              .sort((a, b) => b.score - a.score)
              .map((tone) => (
                <TooltipContent
                  key={tone.name}
                  tone={tone}
                  onImprove={onImprove}
                  type="negative"
                />
              ))}
          </>
        )}
      </Flex>
    </Card>
  );
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "4px",
    marginBottom: "1em",
  },
  title: {
    fontSize: "0.9em",
  },
  toneRow: {
    padding: "4px 0",
    cursor: "pointer",
  },
  divider: {
    margin: "8px 0",
  },
  tooltipContent: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "4px",
  },
  tooltip: {
    maxWidth: "300px",
  },
  emptyState: {
    fontSize: "0.9em",
  },
} as const;

export default ToneAnalysis;
