import React, { useState, useRef, useEffect } from "react";
import { Input, Button, Typography, Spin, Flex } from "antd";
import { IoSend } from "react-icons/io5";
import { BsRobot } from "react-icons/bs";
import {
  LoadingOutlined,
  CloseOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import colors from "../../styles/colors";
import { ChatMessage } from "../../libs/types";

interface ChatModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSendMessage: (message: string) => Promise<void>;
  messages: ChatMessage[];
  loading: boolean;
}

const ChatModal: React.FC<ChatModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  onSendMessage,
  messages,
  loading,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showingChat, setShowingChat] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isOpen) {
      setShowingChat(true);
    } else {
      const timer = setTimeout(() => {
        setShowingChat(false);
      }, 300); // match transition duration
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSend = async () => {
    if (inputValue.trim()) {
      setInputValue("");
      await onSendMessage(inputValue);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  if (!showingChat) {
    return null;
  }

  return (
    <div
      style={{
        ...styles.chatWindow,
        opacity: isOpen ? 1 : 0,
        transform: isOpen ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.3s ease, transform 0.3s ease",
      }}
    >
      <div style={styles.chatHeader}>
        <div style={styles.chatHeaderTitle}>
          <BsRobot size={16} color="white" style={{ marginRight: 6 }} />
          <Typography.Text strong style={{ color: "white", fontSize: "14px" }}>
            Ask AI
          </Typography.Text>
        </div>
        <Flex>
          <Button
            type="text"
            icon={<DeleteFilled style={{ fontSize: "14px" }} />}
            onClick={onDelete}
            style={styles.closeButton}
          />
          <Button
            type="text"
            icon={<CloseOutlined style={{ fontSize: "14px" }} />}
            onClick={onClose}
            style={styles.closeButton}
          />
        </Flex>
      </div>
      <div style={styles.container}>
        <div style={styles.chatContainer}>
          {messages.length === 0 ? (
            <div style={styles.emptyState}>
              <BsRobot size={30} color={colors.primary.main} />
              <Typography.Text style={styles.emptyStateText}>
                I can help with your writing. Ask me anything or try one of
                these:
              </Typography.Text>
              <div style={styles.exampleButtonsContainer}>
                <Button
                  type="default"
                  onClick={() => onSendMessage("Generate initial draft")}
                  style={styles.exampleButton}
                >
                  Write initial draft
                </Button>
                <Button
                  type="default"
                  onClick={() => onSendMessage("Simplify my writing")}
                  style={styles.exampleButton}
                >
                  Simplify my writing
                </Button>
                <Button
                  type="default"
                  onClick={() => onSendMessage("How to sound confident?")}
                  style={styles.exampleButton}
                >
                  How to sound confident?
                </Button>
              </div>
            </div>
          ) : (
            messages.map((message) => (
              <div
                key={message.id}
                style={{
                  ...styles.messageContainer,
                  ...(message.role === "user"
                    ? styles.userMessage
                    : styles.assistantMessage),
                }}
              >
                <div style={styles.messageContent}>
                  <Typography.Text style={styles.messageText}>
                    {message.message}
                  </Typography.Text>
                </div>
              </div>
            ))
          )}
          {loading && (
            <div style={styles.loadingContainer}>
              <Spin
                indicator={
                  <LoadingOutlined style={{ color: colors.primary.main }} />
                }
              />
              <Typography.Text style={styles.loadingText}>
                Thinking...
              </Typography.Text>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div style={styles.inputContainer}>
          <Input.TextArea
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ask a question..."
            autoSize={{ minRows: 1, maxRows: 3 }}
            style={styles.input}
          />
          <Button
            type="primary"
            icon={<IoSend />}
            onClick={handleSend}
            disabled={!inputValue.trim() || loading}
          />
        </div>
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  chatWindow: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "350px",
    height: "300px",
    borderRadius: "4px",
    boxShadow: "0 5px 20px rgba(0, 0, 0, 0.15)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    zIndex: 1000,
    backgroundColor: "white",
  },
  chatButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: colors.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
    zIndex: 1000,
    transition: "all 0.2s ease",
  },
  chatHeader: {
    backgroundColor: colors.primary.main,
    padding: "4px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chatHeaderTitle: {
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    color: "white",
    padding: 4,
    height: "auto",
    width: "auto",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "calc(100% - 50px)",
  },
  chatContainer: {
    flexGrow: 1,
    overflowY: "auto",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  messageContainer: {
    display: "flex",
    gap: "12px",
    maxWidth: "80%",
  },
  userMessage: {
    alignSelf: "flex-end",
    flexDirection: "row-reverse",
    backgroundColor: colors.primary.veryLight,
    borderRadius: "12px",
  },
  assistantMessage: {
    alignSelf: "flex-start",
  },
  messageContent: {
    padding: "12px",
    borderRadius: "12px",
    border: `1px solid ${colors.neutral.lightGray}`,
  },
  messageText: {
    whiteSpace: "pre-wrap",
  },
  inputContainer: {
    display: "flex",
    padding: "4px",
    borderTop: `1px solid ${colors.neutral.gray}`,
    alignItems: "flex-end",
    gap: "4px",
  },
  input: {
    borderRadius: "4px",
    resize: "none",
  },
  sendButton: {
    marginLeft: "8px",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  emptyState: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    gap: "12px",
    padding: "12px",
  },
  emptyStateText: {
    fontSize: "15px",
    color: colors.neutral.gray,
    textAlign: "center",
    marginBottom: "4px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    alignSelf: "flex-start",
  },
  loadingText: {
    color: colors.neutral.gray,
  },
  exampleButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
  },
  exampleButton: {
    textAlign: "left",
    borderColor: colors.primary.light,
    fontSize: "13px",
    height: "32px",
    padding: "0 12px",
  },
};

export default ChatModal;
