import { useCallback, useState } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../libs/firebase";
import { Template, TemplateItem } from "../libs/types";
import { useAuth } from "./useAuth";

export const useCreateTemplate = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const createTemplate = useCallback(
    async (newTemplate: TemplateItem) => {
      setIsLoading(true);
      setError(null);

      try {
        // Generate a new document reference with an ID
        const newDocRef = doc(collection(db, `users/${user?.uid}/templates`));

        // Create the full template object with the generated ID
        const fullTemplate: Template = {
          ...newTemplate,
          id: newDocRef.id,
          createdAt: new Date(),
          updatedAt: new Date(),
          type: newTemplate.type || "default",
        };

        console.log("user", user?.uid);
        // Save the document with the ID included
        await setDoc(newDocRef, fullTemplate);

        setIsLoading(false);
        return fullTemplate;
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred")
        );
        setIsLoading(false);
        throw err;
      }
    },
    [user]
  );

  return { createTemplate, isLoading, error };
};
