import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const { TextArea } = Input;

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values: { email?: string; feedback: string }) => {
    setIsLoading(true);
    try {
      // Simulated API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log("Feedback submitted:", values);
      setIsSubmitted(true);
      form.resetFields();
    } catch (error) {
      console.error("Error saving feedback:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsSubmitted(false);
    onClose();
  };

  return (
    <Modal
      title={null}
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      width={560}
      centered
    >
      <div style={styles.container}>
        {!isSubmitted ? (
          <>
            <div style={styles.header}>
              <h2 style={styles.title}>Share Your Ideas & Feedback</h2>
              <p style={styles.subtitle}>
                Help us make WriteAI better! We'd love to hear your suggestions,
                feature requests, or any thoughts you'd like to share.
              </p>
            </div>

            <Form
              form={form}
              name="feedback"
              onFinish={onFinish}
              layout="vertical"
              style={styles.form}
            >
              <Form.Item
                name="email"
                label="Email (optional)"
                validateTrigger={["onBlur"]}
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Your email address (optional)"
                  style={styles.input}
                />
              </Form.Item>

              <Form.Item
                name="feedback"
                label="Your Feedback"
                rules={[
                  {
                    required: true,
                    message: "Please share your thoughts with us",
                  },
                ]}
              >
                <TextArea
                  placeholder="Share your ideas, suggestions, or feedback here..."
                  rows={4}
                  style={styles.input}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isLoading}
                >
                  Submit Feedback
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <div style={styles.successContainer}>
            <CheckCircleFilled style={styles.successIcon} />
            <h2 style={styles.title}>Thank You! 🙏</h2>
            <p style={styles.subtitle}>
              We really appreciate your feedback. Your input helps us make
              WriteAI better for everyone!
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

const styles = {
  container: {
    padding: "12px 8px",
    textAlign: "center" as const,
  },
  header: {
    marginBottom: "24px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#111827",
    marginBottom: "4px",
  },
  subtitle: {
    color: "#4B5563",
    fontSize: "14px",
  },
  form: {
    textAlign: "left" as const,
  },
  input: {
    borderRadius: "8px",
  },
  successContainer: {
    padding: "32px 16px",
    textAlign: "center" as const,
  },
  successIcon: {
    fontSize: "48px",
    color: "#059669",
    marginBottom: "16px",
  },
};
