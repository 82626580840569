import { Tooltip, TooltipProps } from "antd";
import { useMediaQuery } from "react-responsive";

export function DesktopTooltip({
  children,
  ...props
}: TooltipProps): JSX.Element {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (isMobile) {
    return <>{children}</>;
  }

  return <Tooltip {...props}>{children}</Tooltip>;
}
